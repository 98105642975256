@import "curvy/scss/style";
@import "curvy/scss/utils";
@import "mixins.scss";


html {
	@include color-variable("yellow", #fbb014);
	@include color-variable("orange", #dc6730);
	--dur-s: .15s;
	--dur-m: .3s;
	--dur-l: .6s;
	--grid-gap: .6rem;

	color: rgb(var(--background-text), 0.7) !important;
}

html knw-data-table .table-wrapper .table .table-body-cell {
	white-space: normal;
}

html knw-data-table .table-wrapper.table-sectors .table .table-body-cell,
html knw-data-table .table-wrapper.table-checklists .table .table-body-cell {
	padding: .5em;
}

html knw-data-table .table-wrapper .table .sticky-bottom {
	position: sticky;
	bottom: 0;
	z-index: 100;
}

html knw-data-table label.input.input-labelled {
	flex: 1 0 max-content !important;
	min-width: 120px;
}

html .label.radio-input,
html .label.radio {
	display: inline-flex;
   flex-flow: row nowrap;
   align-items: center;
   max-width: max-content;
   min-width: 2.5em;
   min-height: 2.5em;
   flex: 0 0 auto;
   --radio-color: var(--primary);

	label {
		cursor: pointer;
	}

	& > input {
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 1.125em;
		height: 1.125em;
		max-width: 1.125em;
		max-height: 1.125em;
		background: transparent;
		border: 0.125em solid rgb(var(--radio-color), 1);
		border-radius: 100%;
		position: relative;
		overflow: visible;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: background 0.25s;
		margin: 0.25em;

		&::after {
			position: absolute;
			display: block;
			content: "";
			left: 50%;
			top: 50%;
			width: 75%;
			height: 75%;
			border-radius: 100%;
			transform: translate(-50%, -50%);
			background: rgb(var(--radio-color), 1);
			opacity: 0;
			transition: 0.1s opacity;
		}

		&:checked::after {
			opacity: 1;
		}

		&:focus::before {
			content: "";
			left: -0.5em;
			top: -0.5em;
			bottom: -0.5em;
			right: -0.5em;
			background: rgb(var(--radio-color), 1);
			opacity: var(--alpha-subtle);
			border-radius: 100%;
			position: absolute;
			animation: grow-in 0.1s forwards;
		}
	}
}



i[icon="remove"]+i[icon="remove"] {
	display: none;
}

i[icon="contactless"]::before {
  font-variation-settings:  'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 48!important;
}

html>body {
	height: 100dvh!important;
}

task-root {
	img[src="assets/icons/profile.svg"] {
		opacity: var(--alpha-helper);
		background: white;
	}

	knw-frame>.toolbar {
		z-index: 1000;
	}

	knw-data-table yrd-single-row-filter .header {
		--frame-hl: var(--primary);
		--frame-hl-text: var(--primary-text);
	}

	.hard-column {
		flex-direction: column !important;
	}

	@media only screen and (max-width: 600px) {
		a[href^="mailto"] {
			max-width: 32px;
			padding: 0px 2px;
			font-size: 0;
			color: transparent;

			&::before {
				font-size: 24px;
				color: color(secondary-text);
				top: 1px !important;
			}
		}
	}
}

// layout scrollbar tweak

.knw-sidenav-size-scroll {
	overflow: auto !important;
	height: 100%;
}

.knw-sidenav {
	overflow-y: auto !important;
}

.width-full {
	width: 100%;
}
.field-grid {
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0 8px;

	.whole-row {
		grid-column: 1 / -1;
	}

	knw-search-picker {
		display: contents;
	}

	@media only screen and (max-width: 600px) {
		grid-template-columns: 1fr;
	}
}

knw-auto-search {
	display: contents;

	label.input.input-labelled {
		margin-right: 4px;
		margin-left: 4px;
	}
}

.fullheight-noscroll {
	overflow: hidden;
	height: 100%;
}

.clickable {
	cursor: pointer;

	&:hover {
		background: color(background-light-text, 0.2);
		color: color(background-light-text);
	}
}

.d-contents {
	display: contents;
}

.truncate-text {
	text-overflow: ellipsis;
	max-width: 55ch;
	overflow: hidden;
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;
	padding-right: 0.2em;
}

.truncate-btn-text {
	@extend .truncate-text;
	max-width: 40ch;

	@include max-sm {
		max-width: 25ch;
	}
}

html .table-wrapper .table-header-cell.table-cell.table-column-options:empty {
	z-index: 2;
}

html {

	*{ font-variant-numeric: normal; }

	body {
		@media (pointer: fine) {
			font-size: 13px;
		}

		@media (pointer: coarse) {
			font-size: 14px;
		}
	}


	label.textarea {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		position: relative;
		outline: none;
		min-width: max-content;
		border: none;
		grid-column: 1 / -1;
		border-bottom: 1px solid color(input-color, var(--alpha-medium-emphasis));
		padding: 4px 8px;
		z-index: 0;
		margin-bottom: 7px;
		margin-top: 8px;

		--input-color: var(--background-text);
		--input-color-highlight: var(--primary);
		--input-background: var(--background-text);

		color: color(input-color, var(--alpha-medium-emphasis));

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: transparent;
			border-top-left-radius: 2px;
			border-top-right-radius: 2px;
			z-index: -1;
			transition: background 0.25s;
		}

		&.input-error {
			--input-color: var(--warn);
		}

		&:focus-within {
			border-bottom: 1px solid color(input-color-highlight, var(--alpha-high-emphasis));

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				border-bottom: 1px solid color(input-color-highlight, var(--alpha-high-emphasis));
				animation: underline-fade-in 0.25s forwards;

				@keyframes underline-fade-in {
					from {
						border-color: transparent;
					}

					to {
						border-color: color(input-color-highlight, var(--alpha-high-emphasis));
					}
				}
			}
		}

		&.input-labelled {
			padding-top: 24px;

			.input-label {
				position: absolute;
				font-size: 75%;
				left: 8px;
				top: 4px;
				color: color(input-color, var(--alpha-medium-emphasis));
				transition: color 0.25s;
			}

			&:focus-within .input-label {
				color: color(input-color-highlight, var(--alpha-high-emphasis));
			}

			&.input-required .input-label::after {
				content: " *";
				display: inline-block;
				transform: scale(1.5) translateX(3px);
				color: color(input-color-highlight);
			}
		}

		&.input-error {
			--input-color-highlight: var(--warn);

			.input-label {
				color: color(input-color-highlight, var(--alpha-high-emphasis));
			}

			&:focus-within .input-label {
				color: color(input-color-highlight, var(--alpha-high-emphasis));
			}

			&.input-required .input-label::after {
				color: color(input-color-highlight);
			}
		}

		textarea {
			outline: none;
			border: none;
			background: none;
			color: color(input-color, var(--alpha-high-emphasis));
			caret-color: color(input-color-highlight, var(--alpha-high-emphasis));
			flex: 1 1 100%;
			min-width: 0;
			width: 100%;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: pre-wrap;

			&::placeholder {
				font: var(--body-font);
			}

			&[type="number"],
			&[calculate] {
				font-family: monospace;
				text-align: right;
			}
		}

		&.input-disabled {
			opacity: var(--alpha-disabled);
			pointer-events: none;
		}

		:not(input):not(button):not(select):not(textarea),
		::after,
		::before {
			pointer-events: none;
			user-select: none;
			flex: 0 0 auto;
		}

		// #region Filled
		&.filled {
			&:focus-within {
				border-bottom: 1px solid color(input-color-highlight, var(--alpha-high-emphasis));
				padding-bottom: 4px;

				&::before {
					background: color(input-background, var(--alpha-subtle));
				}
			}

			&::before {
				background: color(input-background, var(--alpha-subtle-low));
			}
		}

		// #endregion

		// #region Outlined

		.input-outline-helper {
			display: none;
			pointer-events: none;
		}

		&.outlined {
			border: none;

			&::before {
				content: none;
			}

			.input-outline-helper {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				bottom: 0;
				display: flex;
				flex-flow: row nowrap;
				transition: border 0.25s;

				&::before,
				&::after,
				.input-outline-helper-text {
					height: 100%;
					border: 1px solid color(input-color, var(--alpha-medium-emphasis));
					box-sizing: border-box;
				}

				&::before {
					content: "";
					border-right: none;
					border-radius: 2px 0 0 2px;
					flex: 0 0 6px;
				}

				.input-outline-helper-text {
					font-size: 75%;
					flex: 0 0 auto;
					border-left: none;
					border-right: none;
					color: transparent;
					padding: 0 3px;
				}

				&::after {
					content: "";
					border-left: none;
					border-radius: 0 2px 2px 0;
					flex: 1 1 0;
				}
			}

			&:hover .input-outline-helper,
			&:active .input-outline-helper {

				.input-outline-helper-text,
				&::after,
				&::before {
					border: 1px solid color(input-color, var(--alpha-high-emphasis));
				}

				.input-outline-helper-text,
				&::after {
					border-left: none;
				}

				.input-outline-helper-text,
				&::before {
					border-right: none;
				}
			}

			&:focus-within .input-outline-helper {

				&::after,
				&::before,
				.input-outline-helper-text {
					border: 2px solid color(input-color-highlight, var(--alpha-high-emphasis));
				}

				&::after,
				.input-outline-helper-text {
					border-left: none;
				}

				&::before,
				.input-outline-helper-text {
					border-right: none;
				}
			}

			&.input-labelled {
				padding: 6px 8px;
				margin-top: 9px;
				margin-bottom: 9px;

				.input-label {
					position: absolute;
					left: 7px;
					top: 0;
					transform: translateY(-66%);
					padding: 0 3px;
				}

				&.input-required .input-outline-helper .input-outline-helper-text {
					padding-right: 1.5em;
				}

				&>.input-outline-helper .input-outline-helper-text {
					border-top: transparent;
				}
			}
		}

		// #endregion

		// #region Hacks
		textarea {
			appearance: none;
			-moz-appearance: none;
		}

		i[icon] {
			margin-top: -3px;
			margin-bottom: -3px;
		}

		textarea:-webkit-autofill {
			transition-delay: 99999999s;
		}

		// #endregion
	}
}

.main-page-wrapper {
	margin-top: 0;
	margin-bottom: 0;
	padding: 2rem 1rem 4rem;

	@include sm {
		padding: 2rem 2rem 4rem;
	}
}

@media only screen and (max-width: 600px) {
	.screen-big {
		display: none !important;
	}
}

@media only screen and (min-width: 601px) {
	.screen-small {
		display: none !important;
	}
}

knw-modal .modal {
	background: color(background-light);
}

.modal-title {
	margin-bottom: 1em;
}

.modal-footer {
	margin-top: 2em;
	justify-content: space-between;
}

.temp-add-button {
	display: flex;
	justify-content: center;
	background-color: color(background-light);
}

.tooltip-icon[tooltip]:hover::after {
	font-size: 12px;
}
html [tooltip]:hover::after {
	min-width: 10ch!important;
}

html [tooltip-right] {
	z-index: 100;
}

.for-toolbar {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	margin: 1.5em 0;

}

.dark-menu-inversion {
	font-size: 75%;

	--primary: var(--b);
	--primary-light: var(--bl);
	--primary-dark: var(--bd);
	--primary-light-text: var(--blt);
	--primary-dark-text: var(--bdt);

	--background: var(--s);
	--background-light: var(--sl);
	--background-dark: var(--sd);
	--background-light-text: var(--slt);
	--background-dark-text: var(--sdt);

	button,
	.fake-button {
		transition: none;
		justify-content: flex-start;

		&::before {
			transition: none;
		}
	}
}

// html {
// 	button.round,
// 	.fake-button.round {
// 		padding: .76em!important;

// 	}
// }

img[clickable] {
	cursor: pointer;
}

.bg-hover {
	transition: all .15s ease-in;
	// border-bottom: 1px solid rgb(var(--primary),var(--alpha-subtle));
	cursor: pointer;
	&:hover {
		border-radius: 0.25em;
		border-bottom: none;
		padding: 0.25em;
		margin: -0.25em;
		background: color(primary, var(--alpha-subtle));
	}
}

details.no-items {
	pointer-events: none;

	summary a,
	summary button {
		pointer-events: all;
	}

	summary::after {
		display: none;
	}
}

.prewrap {
	white-space: pre-wrap;
}

.pointer {
	cursor: pointer;
}

html label.placeholder-warn {
	input::placeholder {
		color: rgb(var(--warn));
	}

	input:focus::placeholder {
		color: transparent;
	}
}

.uppercase {
	text-transform: uppercase;
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

html label.input .input-date-overlay {
	font-size: 95%;
	color:rgb(var(--background-text), var(--alpha-medium-emphasis))
}

html label.input.outlined .input-outline-helper {
	--border: var(--input-color-highlight) !important;
	--alpha: var(--alpha-high-emphasis) !important;
}

.justify-space-around {
	justify-content: space-around;
}

.plain-link {
	background-color: transparent;
	border-radius: 0;
	border: none;
	padding: 0;

	&:hover {
		background-color: transparent;

		span {
			border-color: transparent;
		}
	}

	span {
		border-bottom: .5px solid rgb(var(--background-text));
	}
}

html summary {
	&::after {
		transition-duration: .15s;
	}

	&:hover {
		&::after {
			transform: translateY(-8px) rotate(0deg);
		}
	}
}


.label-pill {
	background: rgb(var(--primary), 1);
	color: rgb(var(--primary-text), var(--alpha-high-emphasis));
	padding: .25em 1em .2em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 100vmax;
	text-decoration: none;
	white-space: nowrap;

	&[color="primary"] {
		background: rgb(var(--secondary), 1);
		color: rgb(var(--secondary-text), var(--alpha-high-emphasis));
		border-bottom: 0.0625em solid rgb(var(--secondary-text), var(--alpha-high-emphasis));
	}

	&[color="secondary"] {
		background: rgb(var(--primary), 1);
		color: rgb(var(--primary-text), var(--alpha-high-emphasis));
	}

	&[color="warn"] {
		background: rgb(var(--warn), 1);
		color: rgb(255, 255, 255, var(--alpha-high-emphasis));
	}

	&[color="yellow-dark"] {
		background: rgb(var(--yellow-dark), 1);
		color: rgb(255, 255, 255, var(--alpha-high-emphasis));
	}
}

html .mobile-wrap-reverse {
	@media only screen and (max-width: 600px) {
		flex-wrap: wrap-reverse !important;
	}
}

.thin-devider {
	border: 0;
	height: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

html details summary.no-arrow::after {
	content: "" !important;
}



/// single pages general styles


.single-page-accordion details {
	background: transparent !important;
	box-shadow: unset !important;
	padding: 0px !important;
}

.whole-row {
	grid-column: 1/-1;
}

.label-pills-container {
	margin-top: .5em;
	gap: .5em;
	display: flex;
	flex-wrap: wrap;

	.label-pill {
		align-self: flex-start;
		font-size: 85%;
	}
}

task-arrival-single,
task-ticket-single,
task-order-single {
	width: 100%;

	.single-page-section {
		margin-bottom: 4rem;

		&.single-page-accordion {
			border: 1px solid rgb(var(--background-dark));
			background: color(background-dark, 1);
			border-radius: 10px;
			padding: 0 1rem !important;
			box-sizing: border-box;
			transition: background .15s ease-in;

			&:hover {
				background: color(background-dark, .5)
			}

			@include max-sm {
				padding: .5rem 1rem !important;
				border: none;
				border-radius: 0;
				width: calc(100% + 2rem);
				margin-left: -1rem;
				background: color(background-dark);
			}
		}
	}

	.sticky-header {
		position: sticky;
		top: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: color(background);
		z-index: 10;
		padding: .3rem 0;
	}

	.enlarge-btn {
		letter-spacing: .1em;
		gap: .6em;
	}

	.single-page-accordion {
		summary {
			margin-bottom: .8rem;

			h2 {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.grid-layout {
		display: flex;
		flex-wrap: wrap;
		gap: var(--grid-gap);
	}

	.grid-card {
		flex: 100 100 300px;
		position: relative;
		min-height: 150px;
		display: flex;
		flex-direction: column;
		background: color(background-light);
		border: 1px solid rgb(var(--background-dark));
		border-radius: 10px;
		padding: 1rem .7rem;
		transition: transform 0.3s ease, box-shadow 0.3s ease;
		opacity: 1;
		box-sizing: border-box;

		@include sm {
			padding: 1rem;
		}
	}

	.card-header {
		display: flex;
		margin-bottom: .5em;
		align-items: center;
		justify-content: space-between;
	}

	.main-info-card {
		.card-header-title {
			border-radius: 10000px;
			padding: 0.5em 1em;
			background: color(primary-light, .9);
			font-weight: 700;
			margin-bottom: 0;
			color: white;
		}
	}

	.card-header-title {
		text-transform: uppercase;
		font-size: 90%;
		letter-spacing: .1em;
	}

	.card-body {
		.heading {
			align-items: baseline;

			h2,
			h3 {
				font-size: 1.5em;
			}

		}
	}

	.total-price {
		font-size: 1.1rem;
		margin-top: 2rem;
		font-weight: 600;
		text-align: right;

		& b, & strong {
			text-transform: uppercase;
			letter-spacing: .1em;
			display: inline-block;
		}
	}




	// Editable table for arrival details and ping pong
	.table-container {
		margin: 0.5em auto;
		background: color(background-light);
		border: 1px solid rgb(180,180,180);
		border-radius: 10px;
		overflow-x: auto;
		position: relative;
		overflow-y: auto;

		table {
			width: 100%;
			border: none;
			border-collapse: collapse;
			table-layout: auto;
			width: 100%;

			--alpha-sublte-table: 0.45;

			label.input.outlined .input-outline-helper {
				// --border: 140,140,140 !important;
				--alpha: var(--alpha-sublte-table) !important;
			}

			label.checkbox-input > input,
			label.checkbox > input  {
				border: 1px solid rgb(var(--checkbox-color), var(--alpha-sublte-table))!important;
			}


			tr {
				width: 100%;
				border-bottom: 1px solid rgb(180,180,180);
				transition: background-color 0.15s ease-in;

				&:not(.expended-details, .header-row):hover {
					background: rgb(var(--background), 1);
				}

				&.row.is-expanded:hover {
					background: rgb(var(--background-dark), 1);
				}

				&.is-deleted-soft:hover {
					background: rgb(var(--warn), .2);
				}

				&.is-deleted-soft {
					background-color: rgb(var(--warn), 0.15);
					border-bottom: .0625em solid rgb(var(--background-light), 1);
				}

				&.is-deleted-hard {
					opacity: 0.5;
				}

				&:not(.expended-details) {
					cursor: pointer;
				}

				&.row:last-child {
					border-bottom: none;
				}
			}

			thead {
				tr {
					background-color: rgb(var(--background-dark));
				}
				tr.row:last-child { border-bottom: 1px solid rgb(180,180,180); }
			}

			th, td {
				width: min-content;
				padding: 4px;

				&.is-centered > div {
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&.is-right > div {
					display: flex;
					justify-content: flex-end;
					align-items: center;
				}

				&.is-changed {
					position: relative;
					border-left: 0.35em solid rgb(var(--warn));

					// &::before {
					// 	content: "";
					// 	width: 2px;
					// 	height: 100%;
					// 	background: rgb(var(--warn), 1);
					// 	border: 1px solid red;
					// 	position: absolute;
					// 	top: 0;
					// 	left: 0;
					// }
				}

				&.is-new {
					position: relative;
					border-left: .35em solid rgb(var(--yellow-light));
				}

				&.is-changed,
				&.is-new {
					.tooltip-icon:hover::before {

						background-color: transparent!important;

					}
				}

				&>div {
					display: flex;
					align-items: center;
					min-height: 33px;
				}

				&.delete-col > div {
					padding: 0;
				}

				.tooltip-icon {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					font-size: 88%;
					text-wrap: nowrap;
					width: 22px;
					height: 22px;
				}

			}

			th {

				.header-cell {
					font-weight: 900;
					color: rgb(var(--background-light-text), var(--alpha-medium-emphasis));
					text-align: left;

					@include max-lg {
						white-space: pre;
					}
				}
			}

			tbody {
				font-family: var(--body-font);
				counter-reset: row;

				td {
					color: rgb(var(--background-light-text), var(--alpha-medium-emphasis));
					font-weight: 400;

				}
			}
		}

		.row-counter::before {
			counter-increment: row;
			content: counter(row) "."
		}

		.expended-details {
			background: rgb(var(--background), 1);

			.details-item {
				padding: 1rem .7rem;
				border-radius: 6px;
				border: 1px solid rgb(var(--background-dark), 1);
				background: rgb(var(--background-light), 1);
				flex: 1;
			}

			.details-changed {
				background: rgb(var(--background-light), 1);
				display: flex;
				flex-flow: column;
				gap: 1rem;


				.changed-params {
					gap: 1rem;
					display: flex;
					flex-flow: column;
					align-items: center;
					justify-content: center;
					flex: 1 0 100%;

					& > span {
						max-width: max-content;
						gap: 1rem;

						& > strong {
							white-space: nowrap;
						}
					}

				}

			}

			.spanned-container {
				padding: 1.5rem .7rem;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				gap: .5rem;
			}

			dl {
				display: flex;
				flex-flow: column;
				gap: .1rem;
				margin: 0;
			}

			.dl-group {
				display: grid;
				grid-template-columns: minmax(auto, 150px) 1fr;
				gap: .1em;
				padding: .1em;

			}

			dt {
				font-weight: 600;
			}

			dd {
				margin: 0;
			}
		}


		.delete-col {
			@include max-lg {
				position: sticky;
				top: 0;
				right: 0;
				z-index: 999;
			}
		}

		th.delete-col {
			z-index: 999;
		}
	}

}
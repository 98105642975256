// breakpoints
@mixin sm {
    @media (min-width: 640px) { @content; }
}

@mixin max-sm {
    @media (max-width: 639px) { @content; }
}

@mixin md {
    @media (min-width: 768px) { @content; }
}

@mixin max-md {
    @media (max-width: 767px) { @content; }
}

@mixin lg {
    @media (min-width: 1024px) { @content; }
}

@mixin max-lg {
    @media (max-width: 1023px) { @content; }
}

@mixin xl {
    @media (min-width: 1280px) { @content; }
}

@mixin max-xl {
    @media (max-width: 1279px) { @content; }
}

@mixin xxl {
    @media (min-width: 1536px) { @content; }
}

@mixin max-xxl {
    @media (max-width: 1535px) { @content; }
}


